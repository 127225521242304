import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MessageForm from './components/MessageForm';
import About from './pages/About';
import DancingButton from './components/DancingButton';
import Messages from './pages/Messages';

function App() {
  const [showForm, setShowForm] = useState(false);

  return (
    <Router>
      <div className="relative min-h-[100dvh]">
        <div className="fixed inset-0 bg-periwinkle-100" />
        <div className="fixed inset-0 bg-gradient-to-br from-periwinkle-100 via-rose-50 to-rose-100 animate-gradient-dance-fast" />
        
        <div className="relative flex flex-col min-h-[100dvh]">
          <header className="p-6 flex justify-center items-center">
            <div className="text-periwinkle-400 font-sans text-3xl md:text-4xl tracking-wide">
              <a href="/" className="group">
                <span className="hover:text-rose-400 transition-colors">Say </span>
                <span className="italic hover:text-rose-400 transition-colors">Hey, </span>
                <span className="hover:text-rose-500 transition-colors">K</span>
              </a>
            </div>
          </header>

          <Routes>
            <Route path="/" element={
              <main className="flex-1 flex flex-col items-center justify-center p-4 text-center">

                {!showForm ? (
                  <>
                    <div className="text-left space-y-4 my-4">
                      <img src="./Images/kSquare.jpeg" alt="Photo of K" className="w-48 h-48 md:w-64 md:h-64 mx-auto my-8 rounded-full overflow-hidden" />
                      <h2 className="text-4xl md:text-6xl text-slate-950 font-sans tracking-wide">
                        Leave Karuna a Birthday Message 💌
                      </h2>
                      <p className="text-xl md:text-2xl text-slate-700 font-sans max-w-4xl mx-auto">
                        Help celebrate Karuna's 30th by sharing a memory, choosing an emoji that reminds you of her, and writing a birthday wish!
                      </p>
                      <p className="text-xl md:text-2xl text-slate-700 font-sans max-w-4xl mx-auto">
                        Your message will be added to a collection of notes from friends that she can carry with her always.
                      </p>
                      <p className="text-xl md:text-2xl text-slate-700 font-sans max-w-4xl mx-auto">
                        The link to the partiful can be found on the <a href="/about" className="text-periwinkle-400 hover:text-periwinkle-500 underline">About page</a> and after sharing a message.
                      </p>
                    </div>
                    <DancingButton onClick={() => setShowForm(true)}>
                      Write a Message
                    </DancingButton>
                  </>
                ) : (
                  <MessageForm />
                )}


              </main>
            } />
            <Route path="/about" element={<About />} />
            <Route path="/messages" element={<Messages />} />
          </Routes>

          <footer className="mt-auto p-6 text-center text-lg text-periwinkle-300">
            <a href="/about" className="text-slate-600 hover:text-periwinkle-400 transition-colors">About Say Hey K</a>
          </footer>
        </div>
      </div>
    </Router>
  );
}

export default App;